@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Turbinado Pro";
  src: url("./assets/font/Turbinado Pro Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/font/Inter_24pt-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  padding-bottom: env(safe-area-inset-bottom);
}
body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: "Inter", sans-serif;
}

@keyframes animate {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
